<template>
    <div class="d-flex mr-4">
        <v-avatar rounded size="150" color="primary darken-1">
            <v-icon size="120" class="primary--text text--lighten-2"
                >mdi-home-city</v-icon
            >
        </v-avatar>
        <div class="ml-4">
            <h3 class="font-weight-regular">Invoice for:</h3>
            <h2 class="text-uppercase">
                {{ property.name }}
            </h2>
            <h4>{{ property.company.name }}</h4>
            <v-divider class="mt-1 mb-2"></v-divider>
            <div class="d-flex">
                <v-icon class="mr-2 primary--text">mdi-account</v-icon>
                <span class="text-subtitle-2">{{
                    $_.get(property, "contact.name", "")
                }}</span>
            </div>
            <div class="d-flex align">
                <v-icon class="mr-2 primary--text">mdi-email</v-icon>
                <span class="text-subtitle-2">{{
                    $_.get(property, "contact.email", "")
                }}</span>
            </div>
            <div class="d-flex">
                <v-icon class="mr-2 primary--text">mdi-phone</v-icon>
                <span class="text-subtitle-2">{{
                    $_.get(property, "contact.phone", "")
                }}</span>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    name: "invoice-property-detail",
    components: {},
    props: {
        property: {
            type: Object,
            required: true,
        },
    },
    data() {
        return {};
    },
};
</script>

<style scoped>
</style>