var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "d-flex mr-4" },
    [
      _c(
        "v-avatar",
        { attrs: { rounded: "", size: "150", color: "primary darken-1" } },
        [
          _c(
            "v-icon",
            {
              staticClass: "primary--text text--lighten-2",
              attrs: { size: "120" }
            },
            [_vm._v("mdi-home-city")]
          )
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "ml-4" },
        [
          _c("h3", { staticClass: "font-weight-regular" }, [
            _vm._v("Invoice for:")
          ]),
          _c("h2", { staticClass: "text-uppercase" }, [
            _vm._v(" " + _vm._s(_vm.property.name) + " ")
          ]),
          _c("h4", [_vm._v(_vm._s(_vm.property.company.name))]),
          _c("v-divider", { staticClass: "mt-1 mb-2" }),
          _c(
            "div",
            { staticClass: "d-flex" },
            [
              _c("v-icon", { staticClass: "mr-2 primary--text" }, [
                _vm._v("mdi-account")
              ]),
              _c("span", { staticClass: "text-subtitle-2" }, [
                _vm._v(_vm._s(_vm.$_.get(_vm.property, "contact.name", "")))
              ])
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "d-flex align" },
            [
              _c("v-icon", { staticClass: "mr-2 primary--text" }, [
                _vm._v("mdi-email")
              ]),
              _c("span", { staticClass: "text-subtitle-2" }, [
                _vm._v(_vm._s(_vm.$_.get(_vm.property, "contact.email", "")))
              ])
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "d-flex" },
            [
              _c("v-icon", { staticClass: "mr-2 primary--text" }, [
                _vm._v("mdi-phone")
              ]),
              _c("span", { staticClass: "text-subtitle-2" }, [
                _vm._v(_vm._s(_vm.$_.get(_vm.property, "contact.phone", "")))
              ])
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }